import { getDate } from '../../utils/share';
import * as actions from './actions';

const INIT_STATE = {
    alert: {
        show: false,
        title: "",
        message: "",
        primaryAction: () => { }
    },
    character: {
        character: { name: '', avatarUrl: '', characterId: '', created: '', createdBy: '' },
        show: false,
    },
    stage: {
        stage: { name: '', avatarUrl: '', stageId: '', created: '', createdBy: '' },
        show: false,
    },
    opponent: {
        opponent: { name: '', avatarUrl: '', opponentId: '', created: '', createdBy: '' },
        show: false,
    },
    girlFriend: {
        girlFriend: { name: '', avatarUrl: '', girlFriendId: '', created: '', createdBy: '' },
        show: false,
    },
    modEvent: {
        event: { backgroundUrl: '', eventId: '', created: '', createdBy: '', title: '', primaryContent: '', secondaryContent: '', publishDate: getDate(), expireDate: getDate() },
        show: false,
    },
    adsBanner: {
        adsBanner: { id: '', adsId: '', text: '', imageUrl: '', bgUrl: '' },
        show: false,
    },
    game: {
        game: { id: '', packageId: '', name: '' },
        show: false,
    }
};

export default (state = Object.assign({}, INIT_STATE), action) => {
    switch (action.type) {
        case actions.SHOW_ALERT_DIALOG:
            const alert = {
                show: true,
                title: action.payload.title,
                message: action.payload.message,
                primaryAction: action.payload.primaryAction
            }
            return { ...state, alert: Object.assign({}, alert) };

        case actions.HIDE_ALERT_DIALOG:
            return { ...state, alert: Object.assign({}, INIT_STATE.alert) };

        // girlFriend
        case actions.SHOW_GIRLFRIEND_DIALOG:
            return { ...state, girlFriend: { ...state.girlFriend, girlFriend: action.payload.girlFriend, show: true } };

        case actions.SHOW_NEW_GIRLFRIEND_DIALOG:
            return { ...state, girlFriend: { show: true, girlFriend: { ...INIT_STATE.girlFriend.girlFriend } } };

        case actions.HIDE_GIRLFRIEND_DIALOG:
            return { ...state, girlFriend: { ...state.girlFriend, show: false } };

        // opponent
        case actions.SHOW_OPPONENT_DIALOG:
            return { ...state, opponent: { ...state.opponent, opponent: action.payload.opponent, show: true } };

        case actions.SHOW_NEW_OPPONENT_DIALOG:
            return { ...state, opponent: { show: true, opponent: { ...INIT_STATE.opponent.opponent } } };

        case actions.HIDE_OPPONENT_DIALOG:
            return { ...state, opponent: { ...state.opponent, show: false } };

        // stage
        case actions.SHOW_STAGE_DIALOG:
            return { ...state, stage: { ...state.stage, stage: action.payload.stage, show: true } };

        case actions.SHOW_NEW_STAGE_DIALOG:
            return { ...state, stage: { show: true, stage: { ...INIT_STATE.stage.stage } } };

        case actions.HIDE_STAGE_DIALOG:
            return { ...state, stage: { ...state.stage, show: false } };
            
            // game
        case actions.SHOW_GAME_DIALOG:
            return { ...state, game: { ...state.game, game: action.payload.game, show: true } };

        case actions.SHOW_NEW_GAME_DIALOG:
            return { ...state, game: { show: true, game: { ...INIT_STATE.game.game } } };

        case actions.HIDE_GAME_DIALOG:
            return { ...state, game: { ...state.game, show: false } };

            // ads banner
        case actions.SHOW_ADS_BANNER_DIALOG:
            return { ...state, adsBanner: { ...state.adsBanner, adsBanner: action.payload.adsBanner, show: true } };

        case actions.SHOW_NEW_ADS_BANNER_DIALOG:
            return { ...state, adsBanner: { show: true, adsBanner: { ...INIT_STATE.adsBanner.adsBanner } } };

        case actions.HIDE_ADS_BANNER_DIALOG:
            return { ...state, adsBanner: { ...state.adsBanner, show: false } };

        // mod event
        case actions.SHOW_MOD_EVENT_DIALOG:
            return { ...state, modEvent: { ...state.modEvent, event: action.payload.event, show: true } };

        case actions.SHOW_NEW_MOD_EVENT_DIALOG:
            return { ...state, modEvent: { show: true, event: { ...INIT_STATE.modEvent.event } } };

        case actions.HIDE_MOD_EVENT_DIALOG:
            return { ...state, modEvent: { ...state.modEvent, show: false } };


        case actions.SHOW_CHARACTER_DIALOG:
            return { ...state, character: { ...state.character, character: action.payload.character, show: true } };

        case actions.SHOW_NEW_CHARACTER_DIALOG:
            return { ...state, character: { show: true, character: { ...INIT_STATE.character.character } } };

        case actions.HIDE_CHARACTER_DIALOG:
            return { ...state, character: { ...state.character, show: false } };

        default:
            return state;
    }
};