export function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  
  export function getTimestampNow() {
    return Date.now();
  }
  
  export function getTimestampByDate(date) {
    return new Date(date).getTime();
  }
  
  export function getDate() {
    return new Date().toUTCString();
  }
  
  // datepart: 'y', 'm', 'w', 'd', 'h', 'n', 's'
  export function dateDiff(datepart, fromDate, toDate) {
    datepart = datepart.toLowerCase();
    var diff = toDate - fromDate;
    var divideBy = {
      w: 604800000,
      d: 86400000,
      h: 3600000,
      n: 60000,
      s: 1000
    };
  
    return diff / divideBy[datepart];
  }
  
  export function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
  
  export function makeid(length) {
    let result = '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() *
        charactersLength));
    }
    return result;
  }

  export const toTitleCase = (phrase) => {
    return phrase
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  export const convertTimestampToDate = (timestamp) => {
    return new Date(timestamp).toLocaleDateString();
  }

  export const sortArrayAsc = ({ sourceArray, propertyName }) => {
    const compare = (a, b) => {
      if (a[propertyName] < b[propertyName]) {
        return -1;
      }
      if (a[propertyName] > b[propertyName]) {
        return 1;
      }
      return 0;
    }
  
    sourceArray.sort(compare);
    return sourceArray;
  }

  export const sortArrayDesc = ({ sourceArray, propertyName }) => {
    const compare = (a, b) => {
      if (a[propertyName] > b[propertyName]) {
        return -1;
      }
      if (a[propertyName] < b[propertyName]) {
        return 1;
      }
      return 0;
    }
  
    sourceArray.sort(compare);
    return sourceArray;
  }