export const SHOW_ALERT_DIALOG = 'SHOW_ALERT_DIALOG';
export const HIDE_ALERT_DIALOG = 'HIDE_ALERT_DIALOG';
export const SHOW_CHARACTER_DIALOG = 'SHOW_CHARACTER_DIALOG';
export const SHOW_NEW_CHARACTER_DIALOG = 'SHOW_NEW_CHARACTER_DIALOG';
export const HIDE_CHARACTER_DIALOG = 'HIDE_CHARACTER_DIALOG';

export const SHOW_STAGE_DIALOG = 'SHOW_STAGE_DIALOG';
export const SHOW_NEW_STAGE_DIALOG = 'SHOW_NEW_STAGE_DIALOG';
export const HIDE_STAGE_DIALOG = 'HIDE_STAGE_DIALOG';

export const SHOW_GIRLFRIEND_DIALOG = 'SHOW_GIRLFRIEND_DIALOG';
export const SHOW_NEW_GIRLFRIEND_DIALOG = 'SHOW_NEW_GIRLFRIEND_DIALOG';
export const HIDE_GIRLFRIEND_DIALOG = 'HIDE_GIRLFRIEND_DIALOG';

export const SHOW_OPPONENT_DIALOG = 'SHOW_OPPONENT_DIALOG';
export const SHOW_NEW_OPPONENT_DIALOG = 'SHOW_NEW_OPPONENT_DIALOG';
export const HIDE_OPPONENT_DIALOG = 'HIDE_OPPONENT_DIALOG';

export const SHOW_MOD_EVENT_DIALOG = 'SHOW_MOD_EVENT_DIALOG';
export const SHOW_NEW_MOD_EVENT_DIALOG = 'SHOW_NEW_MOD_EVENT_DIALOG';
export const HIDE_MOD_EVENT_DIALOG = 'HIDE_MOD_EVENT_DIALOG';

export const SHOW_ADS_BANNER_DIALOG = 'SHOW_ADS_BANNER_DIALOG';
export const SHOW_NEW_ADS_BANNER_DIALOG = 'SHOW_NEW_ADS_BANNER_DIALOG';
export const HIDE_ADS_BANNER_DIALOG = 'HIDE_ADS_BANNER_DIALOG';

export const SHOW_GAME_DIALOG = 'SHOW_GAME_DIALOG';
export const SHOW_NEW_GAME_DIALOG = 'SHOW_NEW_GAME_DIALOG';
export const HIDE_GAME_DIALOG = 'HIDE_GAME_DIALOG';

export const showEditGameDialog = ({ game }) => ({
  type: SHOW_GAME_DIALOG,
  payload: { game }
});
export const showNewGameDialog = () => ({
  type: SHOW_NEW_GAME_DIALOG
});
export const hideGameDialog = () => ({
  type: HIDE_GAME_DIALOG
});
export const showDeleteGameAlert = (primaryAction) => ({
  type: SHOW_ALERT_DIALOG,
  payload: { title: "Delete Game", message: `Do you really want to delete the game?`, primaryAction: primaryAction },
});

export const showEditAdsBannerDialog = ({ adsBanner }) => ({
  type: SHOW_ADS_BANNER_DIALOG,
  payload: { adsBanner }
});
export const showNewAdsBannerDialog = () => ({
  type: SHOW_NEW_ADS_BANNER_DIALOG
});
export const hideAdsBannerDialog = () => ({
  type: HIDE_ADS_BANNER_DIALOG
});
export const showDeleteAdsBannerAlert = (primaryAction) => ({
  type: SHOW_ALERT_DIALOG,
  payload: { title: "Delete Ads Banner", message: `Do you really want to delete the ads banner?`, primaryAction: primaryAction },
});

export const showEditModEventDialog = ({ event }) => ({
  type: SHOW_MOD_EVENT_DIALOG,
  payload: { event }
});
export const showNewModEventDialog = () => ({
  type: SHOW_NEW_MOD_EVENT_DIALOG
});
export const hideModEventDialog = () => ({
  type: HIDE_MOD_EVENT_DIALOG
});
export const showDeleteEventAlert = (eventName, primaryAction) => ({
  type: SHOW_ALERT_DIALOG,
  payload: { title: "Delete Event", message: `Do you really want to delete '${eventName}' event?`, primaryAction: primaryAction },
});

export const showEditOpponentDialog = ({ opponent }) => ({
  type: SHOW_OPPONENT_DIALOG,
  payload: { opponent }
});
export const showNewOpponentDialog = () => ({
  type: SHOW_NEW_OPPONENT_DIALOG
});
export const hideOpponentDialog = () => ({
  type: HIDE_OPPONENT_DIALOG
});
export const showDeleteOpponentAlert = (opponentName, primaryAction) => ({
  type: SHOW_ALERT_DIALOG,
  payload: { title: "Delete opponent", message: `Do you really want to delete '${opponentName}' opponent?`, primaryAction: primaryAction },
});

export const showEditGirlFriendDialog = ({ girlFriend }) => ({
  type: SHOW_GIRLFRIEND_DIALOG,
  payload: { girlFriend }
});

export const showNewGirlFriendDialog = () => ({
  type: SHOW_NEW_GIRLFRIEND_DIALOG
});

export const hideGirlFriendDialog = () => ({
  type: HIDE_GIRLFRIEND_DIALOG
});
export const showDeleteGirlFriendAlert = (girlFriendName, primaryAction) => ({
  type: SHOW_ALERT_DIALOG,
  payload: { title: "Delete girlfriend", message: `Do you really want to delete '${girlFriendName}' girlfriend?`, primaryAction: primaryAction },
});

export const showDeleteSongAlert = (songName, primaryAction) => ({
  type: SHOW_ALERT_DIALOG,
  payload: { title: "Delete song", message: `Do you really want to delete '${songName}' song?`, primaryAction: primaryAction },
});

export const showDeleteCharacterAlert = (characterName, primaryAction) => ({
  type: SHOW_ALERT_DIALOG,
  payload: { title: "Delete character", message: `Do you really want to delete '${characterName}' character?`, primaryAction: primaryAction },
});

export const showDeleteStageAlert = (stageName, primaryAction) => ({
  type: SHOW_ALERT_DIALOG,
  payload: { title: "Delete stage", message: `Do you really want to delete '${stageName}' stage?`, primaryAction: primaryAction },
});

export const showLoginAlert = (error = `Please re-check your email and password!`) => ({
  type: SHOW_ALERT_DIALOG,
  payload: { title: "Login failed", message: error, primaryAction: () => { } },
});

export const hideAlert = () => ({
  type: HIDE_ALERT_DIALOG,
});

export const showEditStageDialog = ({ stage }) => ({
  type: SHOW_STAGE_DIALOG,
  payload: { stage }
});

export const showNewStageDialog = () => ({
  type: SHOW_NEW_STAGE_DIALOG
});

export const hideStageDialog = () => ({
  type: HIDE_STAGE_DIALOG
});

export const showEditCharacterDialog = ({ character }) => ({
  type: SHOW_CHARACTER_DIALOG,
  payload: { character }
});

export const showNewCharacterDialog = () => ({
  type: SHOW_NEW_CHARACTER_DIALOG
});

export const hideCharacterDialog = () => ({
  type: HIDE_CHARACTER_DIALOG
});