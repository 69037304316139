import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { connect } from 'react-redux';
import { hideAlert } from '../../store/dialog-provider/actions';

function AlertDialog({ show, title, message, primaryAction, hideAlertAction }) {
  if (!show) return false;

  return (
    <Dialog
      open={show}
      onClose={() => hideAlertAction()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="dialog-provider"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => hideAlertAction()} color="secondary">
          Close
        </Button>
        <Button onClick={() => {
          hideAlertAction();
          primaryAction();
        }} color="primary" autoFocus>
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = state => {
  return {
    show: state.dialogProvider.alert.show,
    title: state.dialogProvider.alert.title,
    message: state.dialogProvider.alert.message,
    primaryAction: state.dialogProvider.alert.primaryAction
  }
}

const mapDispathToProps = {
  hideAlertAction: hideAlert
}

export default connect(mapStateToProps, mapDispathToProps)(AlertDialog);
