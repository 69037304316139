import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import zIndex from "@material-ui/core/styles/zIndex";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  card: {
    height: "100%",
  },
  iframe: {
    "& iframe": {
      width: "100%",
      height: "80vh",
      minHeight: "80vh",
    },
  },
  iframeFullscreen: {
    "& iframe": {
      width: "100vw",
      height: "100vh",
      minHeight: "100vh",
      minWidth: "100vw",
      position: "fixed",
      border: "none",
      top: 0,
      left: 0,
      zIndex: zIndex.drawer + 100000,
      overflow: "hidden",
    },
  },
  iframeFullscreenIcon: {
    position: "absolute",
    top: theme.spacing(1) * 2,
    right: theme.spacing(1) * 3,
    zIndex: zIndex.drawer + 200000,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    marginBottom: 16,
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}));

function SimpleCard({ songData }) {
  const classes = useStyles();
  // listen route changed
  const history = useHistory();
  history.listen((location, action) => {
    console.log("location", location);
    console.log("action", action);
    if (action === "PUSH") {
      const newSong = getSong(location.pathname.split("/")[1]);
      setSong({ ...newSong });
    }
  });

  const getSong = (songId) =>
    songData.find((s) => s.id == songId)
      ? songData.filter((s) => s.id == songId)[0]
      : songData[0];

  // get current uri. example: http://localhost:3000/alive2 => alive2
  const songId = window.location.pathname.split("/")[1];
  const [song, setSong] = useState(getSong(songId));
  const [isFullscreen, setIsFullscreen] = useState(false);
  console.log("Selected ", song);

  const handleFullscreen = () => {
    const fullscreen = !isFullscreen;
    setIsFullscreen(fullscreen);
    // request fullscreen
    if (!fullscreen) {
      document.exitFullscreen();
    } else {
      document.documentElement.requestFullscreen();
    }
  };

  return (
    <>
      <Card className={classes.card}>
        <CardContent
          className={isFullscreen ? classes.iframeFullscreen : classes.iframe}
        >
          <iframe
            src={song.url}
            title={`Sprunki - ${song.title}`}
            frameBorder="0"
            width="100%"
            height="100%"
            loading="lazy"
            allowFullScreen
            allow="none"
          ></iframe>
          <IconButton
            onClick={handleFullscreen}
            aria-labelledby={`iframe-full-screen`}
            className={classes.iframeFullscreenIcon}
            color="secondary"
          >
            {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
          </IconButton>
        </CardContent>
        <CardContent>
          <h1>{song.name}</h1>
          <div dangerouslySetInnerHTML={{ __html: song.description }} />
        </CardContent>
      </Card>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    songData: state.sprunki.songData,
  };
};

export default connect(mapStateToProps, null)(SimpleCard);
