export function deepClone(o) {
    return JSON.parse(JSON.stringify(o));
}

export function getAudioExtension(downloadUrl) {
    return downloadUrl.indexOf("ogg") > 0 ? "ogg" : "mp3";
}

export function downloadAsCsv(arrayObjects, fileName) {
    const csvContent = "data:text/csv;charset=utf-8,"
        + "Status,Song id,Song name,Easy,Normal,Hard,Audio,Voice\n"
        + arrayObjects.map(e => Object.values(e).toString()).join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", fileName);
    document.body.appendChild(link);

    link.click();
    document.body.removeChild(link);
}

export function convertTimestampToLocalTime(timeStamp)
{
    return new Date(timeStamp).toLocaleString();
}