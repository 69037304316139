import * as actions from './actions';
import { deepClone } from '../../utils/share/objects';

const DefaultSongData = [
    {
        "id": "origin",
        "name": "Origin",
        "url": "https://user-cdn.metanotegame.com/origin2.html"
    }
]
const INIT_STATE = {
    error: '',
    songData: DefaultSongData,
};

export default (state = deepClone(INIT_STATE), action) => {
    switch (action.type) {
        case actions.LOAD_SONG_DATA_SUCCESS:
            console.log("action.payload.songData", action.payload.songData);
            return {
                ...state,
                songData: [...action.payload.songData],
            };

        default:
            return state;
    }
};