import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';

// remove
export function* watchLoadSongData() {
    yield takeLatest(actions.LOAD_SONG_DATA, loadSongData);
}
const loadSongDataAsync = async () => {
    const songDataUrl = "https://cdn.metanotegame.com/sprunki/songData-all.json";
    // fetch song data by http
    // no-cors is used to avoid CORS error
    return await fetch(songDataUrl, {
      mode: "cors",
      method: "GET",
    })
      .then(async (response) => {
        const data = await response.json();
        console.log("data", data);
        return put(actions.loadSongDataSuccess({ songData: data }));
      })
      .catch((error) => {
        throw error;
      });
}
function* loadSongData() {
    try {
        const result = yield call(loadSongDataAsync);
        yield result;
    } catch (error) {
        console.error(error);
        yield put(actions.loadSongDataFailed(error));
    }
}


export default function* rootSaga() {
    yield all([
        fork(watchLoadSongData),
    ]);
}