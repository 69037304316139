import { Header, NotificationCenter, Sidebar, Workspace } from "../components";
import React, { useState, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import FormatTextdirectionLToRIcon from "@material-ui/icons/FormatTextdirectionLToR";
import FormatTextdirectionRToLIcon from "@material-ui/icons/FormatTextdirectionRToL";
import Hidden from "@material-ui/core/Hidden";
import { MobileBreakpoint } from "../styleVariables";
import SettingsIcon from "@material-ui/icons/Settings";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import WbSunnyIcon from "@material-ui/icons/WbSunny";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import routes from "../routes";
import { useAppState } from "../components/AppProvider/AppProvider";
import useMountEffect from "../mountEffect";
import { connect } from "react-redux";
import { loadSongData } from "../store/sprunki/actions";

const useStyles = makeStyles((theme) => ({
  panel: {
    position: "relative",
    overflow: "hidden",
    width: "100%",
    maxWidth: "100%",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
      minHeight: "calc(100vh)",
    },
    [theme.breakpoints.down("xs")]: {
      height: "auto",
      minHeight: "calc(100vh)",
    },
    [theme.breakpoints.up("sm")]: {
      height: "calc(100vh)",
    },
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
  },
  speedDial: {
    position: "fixed",
    bottom: theme.spacing(1) * 2,
    right: theme.spacing(1) * 3,
  },
}));

const Dashboard = ({ history, songData, loadSongDataAction }) => {
  const classes = useStyles();
  const [state, dispatch] = useAppState();
  const [opened, setOpened] = useState(true);
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const [openSpeedDial, setOpenSpeedDial] = useState(false);
  const role = "anonymous";
  const mediaMatcher = matchMedia(`(max-width: ${MobileBreakpoint}px)`);

  // get current uri. example: http://localhost:3000/alive2 => alive2
  const currentUri = window.location.pathname.split("/")[1];

  useMountEffect(() => {
    loadSongDataAction();
  });

  const resizeDispatch = () => {
    if (typeof Event === "function") {
      window.dispatchEvent(new Event("resize"));
    } else {
      const evt = window.document.createEvent("UIEvents");
      evt.initUIEvent("resize", true, false, window, 0);
      window.dispatchEvent(evt);
    }
  };

  const handleDrawerToggle = () => {
    setOpened(!opened);
    resizeDispatch();
  };

  const handleNotificationToggle = () =>
    setNotificationsOpen(!notificationsOpen);

  const handleFullscreenToggle = () => {
    const element = document.querySelector("#root");
    const isFullscreen =
      document.webkitIsFullScreen || document.mozFullScreen || false;

    element.requestFullScreen =
      element.requestFullScreen ||
      element.webkitRequestFullScreen ||
      element.mozRequestFullScreen ||
      function () {
        return false;
      };
    document.cancelFullScreen =
      document.cancelFullScreen ||
      document.webkitCancelFullScreen ||
      document.mozCancelFullScreen ||
      function () {
        return false;
      };
    isFullscreen ? document.cancelFullScreen() : element.requestFullScreen();
  };

  const handleSpeedDialOpen = () => setOpenSpeedDial(!openSpeedDial);

  const handleSpeedDialClose = () => setOpenSpeedDial(false);

  const getRoutes = (songData) => (
    <Switch>
      {routes[role].items.map((item, index) => {
        if (item.isSondData == "true") {
          const mainRoute = (
            <Route
              exact
              path={item.path}
              component={item.component}
              name={item.name}
              key={index}
            />
          );
          const allRoutes = songData.map((subItem) => (
            <Route
              exact
              path={`${item.path}${subItem.id}`}
              component={item.component}
              name={subItem.name}
            />
          ));

          allRoutes.push(mainRoute);
          return allRoutes;
        } else if (item.type === "submenu") {
          const mainRoute = (
            <Route
              exact
              path={item.path}
              component={item.component}
              name={item.name}
              key={index}
            />
          );
          const allRoutes = item.children.map((subItem) => (
            <Route
              exact
              path={`${item.path}${subItem.path}`}
              component={subItem.component}
              name={subItem.name}
            />
          ));

          allRoutes.push(mainRoute);
          return allRoutes;
        }
      })}
      {songData.find((s) => s.id == currentUri) ? (
        <Redirect to={`${currentUri}`} />
      ) : (
        false
      )}

      {songData.length > 1 && !songData.find((s) => s.id == currentUri) ? (
        <Redirect to="/404" />
      ) : (
        false
      )}
    </Switch>
  );

  const [routeElementState, setRouteElementState] = useState(
    getRoutes(songData)
  );
  const [routeState, setRouteState] = useState(routes[role].items);

  useEffect(() => {
    console.log("songData changed", songData);
    setRouteElementState(getRoutes(songData));
    const items = routes[role].items.map((i) => {
      if (i.isSondData == "true") {
        return {
          ...i,
          children: songData.map((s) => ({
            ...s,
            path: `${s.id}`,
            component: i.component,
          })),
        };
      }
      return i;
    });
    console.log("items", items);
    setRouteState(items);
  }, [songData]);

  useMountEffect(() => {
    if (mediaMatcher.matches) setOpened(false);
    mediaMatcher.addListener((match) => {
      setTimeout(() => {
        if (match.matches) setOpened(false);
        else setOpened(true);
      }, 300);
    });

    const unlisten = history.listen(() => {
      if (mediaMatcher.matches) setOpened(false);
      document.querySelector("#root > div > main").scrollTop = 0;
    });

    return () => {
      unlisten();
      mediaMatcher.removeListener((match) => {
        setTimeout(() => {
          if (match.matches) setOpened(false);
          else setOpened(true);
        }, 300);
      });
    };
  });

  return (
    <>
      <Header
        logoAltText="Sprunki Metanote"
        logo={`${process.env.PUBLIC_URL}/static/images/logo.png`}
        toggleDrawer={handleDrawerToggle}
        toogleNotifications={handleNotificationToggle}
        toggleFullscreen={handleFullscreenToggle}
      />
      <div className={classNames(classes.panel, "theme-dark")}>
        <Sidebar
          routes={routeState}
          opened={opened}
          toggleDrawer={handleDrawerToggle}
        />
        <Workspace opened={opened}>{routeElementState}</Workspace>
        <NotificationCenter
          notificationsOpen={notificationsOpen}
          toogleNotifications={handleNotificationToggle}
        />
      </div>

      <SpeedDial
        ariaLabel="Settings"
        className={classes.speedDial}
        icon={<SpeedDialIcon icon={<SettingsIcon />} />}
        onClick={handleSpeedDialOpen}
        open={openSpeedDial}
      >
        <SpeedDialAction
          icon={<WbSunnyIcon />}
          tooltipTitle="Toggle light/dark theme"
          onClick={() => dispatch({ type: "type" })}
        />
      </SpeedDial>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    songData: state.sprunki.songData,
  };
};

const mapDispatchToProps = {
  loadSongDataAction: loadSongData,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
