export const LOAD_SONG_DATA = 'LOAD_SONG_DATA';
export const LOAD_SONG_DATA_SUCCESS = 'LOAD_SONG_DATA_SUCCESS';
export const LOAD_SONG_DATA_FAILED = 'LOAD_SONG_DATA_FAILED';

export const loadSongData = () => ({
  type: LOAD_SONG_DATA,
});
export const loadSongDataSuccess = ({ songData }) => ({
  type: LOAD_SONG_DATA_SUCCESS,
  payload: { songData },
});
export const loadSongDataFailed = ({ error }) => ({
  type: LOAD_SONG_DATA_FAILED,
  payload: { error },
});
