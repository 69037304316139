import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
});

function DatePickers(props) {
  const { classes, defaultValue, title, name, required } = props;

  return (
    <TextField
      id="date"
      type="date"
      {...required != undefined ? { ...required } : false}
      label={title}
      defaultValue={defaultValue}
      className={classes.textField}
      InputLabelProps={{
        shrink: true,
      }}
      name={name}
    />
  );
}

DatePickers.defaultProps = {
  defaultValue: "2022-01-01",
  title: "Next appointment",
  name: "unknown",
  required: undefined
}

DatePickers.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DatePickers);
