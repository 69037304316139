import React from 'react';
import AlertDialog from './AlertDialog';
import './DialogProvider.scss';

function DialogProvider({ children }) {
    return (
        <>
            {children}
            <AlertDialog />
        </>
    );
}

export default DialogProvider;
