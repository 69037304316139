// Pages
import {
  AppBar,
  Autocomplete,
  Avatars,
  BackendError,
  Badges,
  Blank,
  ButtonNavigation,
  Buttons,
  Calendar,
  Cards,
  Charts,
  Chat,
  Chips,
  Detail,
  Dialogs,
  Dividers,
  Drawers,
  Editor,
  ExpansionPanels,
  GridList,
  Home,
  Invoice,
  Lists,
  Lockscreen,
  ManageSongs,
  ManageAllSongs,
  Media,
  Menus,
  Messages,
  NotFound,
  Paper,
  PasswordReset,
  Pickers,
  PricingPage,
  Products,
  Progress,
  SelectionControls,
  Selects,
  Signin,
  Signup,
  Snackbars,
  Social,
  Steppers,
  Tables,
  Tabs,
  Taskboard,
  TextFields,
  TimelinePage,
  Tooltips,
  Widgets,
} from './pages';

import MusicVideo from '@material-ui/icons/MusicVideo';

const anonymous = {
  items: [
    {
      path: "/",
      name: "All modes",
      type: "submenu",
      icon: MusicVideo,
      component: Cards,
      isSondData: "true",
      children: [
        {
          path: "origin",
          name: "Origin",
        },
      ],
    },
  ],
};

export default {
  anonymous,
}
