import * as sprunkiActions from '../sprunki/actions';
import * as actions from './actions';
import { NotificationTypes } from '../../model/layout';
import { deepClone } from '../../utils/share/objects';

const INIT_STATE = {
    progress: {
        show: true
    },
    notification: {
        message: "",
    }
};

export default (state = deepClone(INIT_STATE), action) => {
    switch (action.type) {
        case sprunkiActions.LOAD_SONG_DATA:
            return {
                ...state, progress: {
                    show: true
                },
            };

        case sprunkiActions.LOAD_SONG_DATA_FAILED:
            return {
                ...state, notification: { type: NotificationTypes.error, message: "Failed!!!" }, progress: {
                    show: false
                }
            }

        case sprunkiActions.LOAD_SONG_DATA_SUCCESS:
            return {
                ...state, progress: {
                    show: false
                },
            };

        case actions.HIDE_NOTIFICATION: {
            return {
                ...state, notification: { type: NotificationTypes.none, message: "" }
            }
        }

        default:
            return state;
    }
};