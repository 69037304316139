export const SHOW_ALERT_DIALOG = 'SHOW_ALERT_DIALOG';
export const HIDE_ALERT_DIALOG = 'HIDE_ALERT_DIALOG';
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';

export const showDeleteSongAlert = (songName, primaryAction) => ({
  type: SHOW_ALERT_DIALOG,
  payload: { title: "Delete song", message: `Do you really want to delete '${songName}' song?`, primaryAction: primaryAction },
});

export const hideAlert = () => ({
  type: HIDE_ALERT_DIALOG,
});

export const hideNotificatioin = () => ({
  type: HIDE_NOTIFICATION
})