import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
});

function DateAndTimePickers(props) {
  const { classes, defaultValue, title, name, required } = props;

  return (
    <TextField
      {...required != undefined ? { ...required } : false}
      id="datetime-local"
      label={title}
      type="datetime-local"
      defaultValue={defaultValue}
      className={classes.textField}
      InputLabelProps={{
        shrink: true,
      }}
      name={name}
    />
  );
}

DateAndTimePickers.defaultProps = {
  defaultValue: "2017-05-25T10:30",
  title: "Next appointment",
  name: "unknown",
  required: undefined
}

DateAndTimePickers.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DateAndTimePickers);
