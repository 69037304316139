import { combineReducers } from 'redux';
import dialogProvider from './dialog-provider/reducer';
import layout from './layout/reducer';
import sprunki from './sprunki/reducer';

const reducers = combineReducers({
    dialogProvider,
    layout,
    sprunki
});

export default reducers;