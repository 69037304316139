import {
  BackendError,
  NotFound,
} from "./pages";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import AppProvider from "./components/AppProvider/AppProvider";
import Dashboard from "./containers/Dashboard";
import React from "react";
import { Provider } from 'react-redux';
import registerServiceWorker from "./registerServiceWorker";
import { render } from "react-dom";
import { configureStore } from "./store";
import ErrorBoundary from "./components/ErrorBoudary";
import DialogProvider from "./components/DialogProvider/DialogProvider";

//config();

const store = configureStore({});

render(
  <Provider store={store}>
    <AppProvider>
      <DialogProvider>
        <ErrorBoundary>
          <BrowserRouter basename={process.env.PUBLIC_URL}>
            <Switch>
              <Route exact path="/404" component={NotFound} />
              <Route exact path="/500" component={BackendError} />
              <Route path="/" component={Dashboard} />
            </Switch>
          </BrowserRouter>
        </ErrorBoundary>
      </DialogProvider>
    </AppProvider>
  </Provider>,
  document.getElementById("root")
);

registerServiceWorker();
